<template>
    <div class="edu-frame">
        <edu-nav-bar title="活动简介" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
			<div class="edu-group class-item" style="height: calc(95% - 20px);">
				<div class="class-detail">
					<div class="class-detail-title" style="padding-top:20%;text-align: center;">
						{{activityName}}
					</div>
					<div class="class-detail-row-title" style="padding-top:10%;">
						项目简介：{{intro}}
					</div>
					<div class="class-detail-row-title" style="width: 80%;position:absolute;bottom:10%;padding-left: 5%;">
						<van-button style="width: 100%" @click="todetail" type="danger" round size="small">{{isNeedPay?'支付':'登记'}}</van-button>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
    import {Button, Tag, Checkbox, Rate, Dialog} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import BaomingApi from "@/api/BaomingApi";
    import Tools from "@/api/Tools";
    import EduConstants from '@/api/EduConstants';
    import XEUtils from 'xe-utils';
	import ActivityApi from "@/api/ActivityApi";

    export default {
        components: {
            EduNavBar,
            VanButton: Button,
            VanTag: Tag,
            VanCheckbox: Checkbox,
			VanRate: Rate,
			[Dialog.Component.name]: Dialog.Component,
        },
        filters: {
            filterDate(date) {
                return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
            }
        },
        data() {
            return {
				show: false,
				submitting: false,
				cust: Tools.getCurCust(),
				activtys:[],
				addParams:{},
				activitySettingMasterID:this.$route.query.activitySettingMasterID,
				activityName:this.$route.query.activityName,
				intro:this.$route.query.intro,
				isNeedPay:this.$route.query.isNeedPay,
            }
        },
        methods: {
			todetail(){
				this.$router.push({name: 'hdglmx', query: {activitySettingMasterID:this.activitySettingMasterID}})
			},
        },
        mounted() {
        }
    }
</script>
<style scoped>
	.row_title{
		display: inline-block;
		padding-right: 2em;
		min-width: 5em;
		color: gray;
	}
</style>
